import { faGithub, faLinkedinIn, faChrome } from '@fortawesome/free-brands-svg-icons';
import { faServer, faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import GiDiploma from "../static/diploma.svg";

export const Introduction = {
	name: 'M. Angel Jacquel',
	email: 'ajacquel@devight-laboratory.ovh',
	website: 'cv.devight-laboratory.ovh',
	social_icons: [
		{
			link: 'https://github.com/Nikki-Devil',
			label: 'Github',
			icon: faGithub,
		},
		{
			link: 'https://fr.linkedin.com/in/angel-jacquel-760974212',
			label: 'LinkedIn',
			icon: faLinkedinIn,
		},
		{
			link: 'https://cv.devight-laboratory.ovh',
			label: 'Projet Devight Laboratory',
			icon: faChrome,
		},
		{
			link: 'https://cv.8e-couche.ovh',
			label: 'Projet 8e Couche',
			icon: faServer,
		},
		{
			link: 'https://bts.8e-couche.ovh',
			label: 'PortFolio BTS SIO SISR',
			icon: faGraduationCap,
		},
	]
}

export const WhatsNewAboutExperienceEducationSkills = [
  {
    title: "Actualité",
		details: [
			{
				name: "2021 - L'idée d'un laboratoire",
				detail: "Avec un ami, nous avons eu l'idée de créer un laboratoire de recherche et développement."
			},
			{
				name: "2022 - La naissance du laboratoire",
				detail: "Bien que la création d'une entreprise de recherche soit hors de notre portée pour le moment, " +
				"ce serveur a été mis en place. Il nous permet de tester nos idées et partager des projets avec nos amis.",
			},
			{
                                name: "2023 - R&D intense",
                                detail: "J'ai beaucoup expérimenté en 2023, réinstallé de nombreuses fois mes serveurs " +
                                "et corrigé nombres problèmes que je pouvais créer. Je les ai même optimisés à chaque installations du fait de mon " +
				"apprentissage constant et de ma montée en compétences, me permettant de faire moi-même ce que je faisais au début avec des outils externes",
                        },
                        {
                                name: "Right now !",
                                detail: "A l'heure actuelle, le serveur du Devight-Laboratory est un serveur essentiellement de tests et de jeux. " +
				"Tout les projets 8e-couche sont testé dessus avant d'être installé là-bas. Pour ce qui est du jeux, le Devight-Laboratory " +
				"est doté du service Crafty Controller (pour une gestion rapide et simple, partagée aux modérateurs)" +
				", d'un versionning de configurations par un git privé, un WikiJS pour le modpack Earth Country et de cette page.",
                        },
			{
				name: "2024 - Le projet Earth Country",
				detail: "EarthCountry est un projet de serveur Minecraft privé, basé sur un modpack créé par nos soins. Bien qu'une première version " +
				"Ai déjà été lancée en 2020, cette nouvelle version sera plus aboutie, plus complète et est développée à l'aide de Github et un Devlog sur notre" +
				" Discord privé, contrairement à la première itération.",
				reference_links: [
					{
							link:"https://github.com/EarthCountry-modpack",
							logo: faGithub
					},
				]
			},
		]
	},
	{
		title: 'Autre projets',
		details: [
			{
				name: "8e Couche",
				web_url: 'https://cv.8e-couche.ovh',
				reference_links: [
				]
			},
			{
				name: "Porte Folio BTS SIO SISR",
				web_url: 'https://bts.8e-couche.ovh',
				reference_links: [
				]
			},
                        {
                                name: "Maquette BTS - Infra Light-Snoop",
                                web_url: 'https://github.com/E5-MMGE/Schema/blob/main/E5%20-%20Schematic%20-%20Dark.png',
                                reference_links: [
                                ]
                        },
			{
				name: "Earth Country",
				web_url: 'https://github.com/EarthCountry-modpack',
				reference_links: [
				]
			},
		]
	},
	{
		title: 'À propos',
		details: [
			{
					major: "En 3ème année à l'ESGI en alternance, J'ai mis en place le serveur du Devight Laboratory ainsi que celui " +
					"de la 8ème couche. Je suis un passioné d'informatique, d'astrophysique et un grand joueur de Minecraft."
			}
		]
	},
	{
		title: "Experiences",
		details: [
			{
				name: "Axians",
				web_url: 'https://www.axians.fr/',
				major: "Administrateur Système et Réseau - Alternance",
				date: 'Aout 2024 - Septembre 2025',
				detail: null,
			},
			{
				name: "Bioburger, chaine de burger bio.",
				web_url: 'https://www.bioburger.fr/',
				major: "Manager",
				date: 'Aout 2022 - Présent',
				detail: null,
			},
			{
				name: "KNCO, lunetier français.",
				web_url: 'https://knco.fr/',
				major: "Technicien informatique - Alternance",
				date: 'Oct 2022 - Août 2024',
				detail: null,
			},
			{
				name: "Etablissement Sainte Geneviève Courbevoie.",
				web_url: 'https://stgsp-courbevoie.fr/',
				major: "Techicien informatique",
				date: 'Sep 2021 - Feb 2022',
				detail: null,
			}
		]
	},
  {
    title: "Ecoles",
		details: [
                        {
                                name: "ESGI, Paris",
                                web_url: 'https://www.esgi.fr/',
                                major: "Systèmes, Réseaux et Cloud Computing",
                                date: '2024 - Futur',
                                detail: null,
                        },
			{
				name: "Fenelon Sup, Paris",
				web_url: 'https://www.fenelonsup.org/',
				major: "BTS SIO SISR",
				date: '2021 - 2024',
				detail: null,
			},
			{
				name: "Lycée Saint Joseph, Argenteuil",
				web_url: 'http://www.stjosephargenteuil.fr/',
				major: "Bac Général, équivalent de l'ancien S",
				date: '2019 - 2021',
				detail: null,
			}
		]
  },
  {
    title: "Skills, Technologies & Tools",
    skills: true
  }
]

export const Skills = [
        'devicon-python-plain-wordmark colored',
        'devicon-windows8-original colored',
        'devicon-atom-plain-wordmark colored',
        'devicon-linux-plain',
        'devicon-debian-plain-wordmark colored',
        'devicon-ubuntu-plain-wordmark colored',
        'devicon-git-plain-wordmark colored',
        'devicon-github-original-wordmark',
        'devicon-android-plain-wordmark colored',
        'devicon-jira-plain colored',
        'devicon-markdown-original',
        'https://devicon.dev/',
]
